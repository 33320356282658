import { Routes, Route, useNavigate } from "react-router-dom";
import { useState } from "react";
import { logOutRequest } from "../services/Modules/Auth";
import { useDispatch } from "react-redux";
import LandingLayout from "components/Organisms/Layout/LandingPageLayout";
import LandingHomePage from "Pages/LandingPage/Home/LandingHomePage";
import PricingPage from "Pages/LandingPage/Pricing/PricingPage";
import FeaturesPage from "Pages/LandingPage/Features/FeaturesPage";
import SignUpPage from "Pages/LandingPage/SignUp/SignUpPage";
import AboutUsPage from "Pages/LandingPage/About/AboutUsPage";
import ContactUsPage from "Pages/LandingPage/Contact/ContactUsPage";
import BlogPage from "Pages/LandingPage/Blog/BlogPage";
import AppRoute from "./Route";

const LandingPage = () => {
  // this state is for update breadcrumb data for each page
  const [layoutData, setLayoutData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutFun = () => {
    dispatch(logOutRequest());
    localStorage.removeItem("userToken");
    localStorage.removeItem("configData");
    navigate("/");
  };
  const [bgColor, setBgColor] = useState("#ffff");

  return (
    <Routes>
      {/* admin layout */}
      <Route
        index
        path="/website"
        element={<AppRoute/>}
      />
      <Route
        path="/"
        element={
          <LandingLayout
            layoutData={layoutData}
            logOutFun={logOutFun}
            bgColor={bgColor}
          />
        }
      >
         <Route
          index
          element={
            <LandingHomePage setLayoutData={setLayoutData} setBgColor={setBgColor} />
          }
        />
        
        <Route
          path="/signup"
          element={<SignUpPage setBgColor={setBgColor} />}
        />
        <Route
          path="/pricing"
          element={<PricingPage setBgColor={setBgColor} />}
        />
        <Route
          path="/features"
          element={<FeaturesPage setBgColor={setBgColor} />}
        />
        <Route
          path="/about"
          element={<AboutUsPage setBgColor={setBgColor} />}
        />
        <Route
          path="/contact"
          element={<ContactUsPage setBgColor={setBgColor} />}
        />
        <Route
          path="/blog"
          element={<BlogPage setBgColor={setBgColor} />}
        />
        </Route>
    </Routes>
  );
};

export default LandingPage;
