import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useState } from "react";
import CoursesOverviewPage from "../Pages/Courses/CoursesOverview/CoursesOverviewPage";
import CourseDetailsPage from "../Pages/Courses/CourseDetails/CourseDetailsPage";
import CoursesListPage from "../Pages/Courses/CoursesList/CoursesListPage";
import HomePage from "../Pages/Home/HomePage";
import NotFound from "../components/Atoms/SharedComponents/NotFound";
import EbookPage from "../Pages/Courses/E-book/EbookPage";
import { logOutRequest } from "../services/Modules/Auth";
import { useDispatch } from "react-redux";
import AssignmentContent from "../components/Organisms/Courses/AssignmentContent/AssignmentContent";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import ProfileLayout from "../components/Organisms/Layout/ProfileLayout";
import Layout from "../components/Organisms/Layout/MainLayout";
import MyCoursesPage from "../Pages/Profile/MyCourses.Page";
import SavedListPage from "../Pages/Profile/SavedList.Page";
import SettingPage from "../Pages/Profile/Setting.Page";
import WalletPage from "Pages/Profile/Wallet.Page";
import ZoomMeeting from "components/Molecules/Courses/VideoContent/ZoomMeeting";
import CartPage from "Pages/Cart/CartPage";
const AppRoute = () => {
  // this state is for update breadcrumb data for each page
  const [layoutData, setLayoutData] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutFun = (path) => {
    dispatch(logOutRequest());
    localStorage.removeItem("userToken");
    localStorage.removeItem("configData");
    navigate(path);
  };
  const [bgColor, setBgColor] = useState("rgba(0, 0, 0, 0.07)");
  // console.log(window.location.origin.toString());
  // console.log(window.location.origin.toString().includes('website'));
  return (
    <Routes>
      <Route path="/" element={<Navigate to={'/website'}/>} />
      <Route
        path="/website"
        element={
          <Layout
            layoutData={layoutData}
            logOutFun={(_) => logOutFun("/website")}
            bgColor={bgColor}
          />
        }
      >
        <Route
          index
          element={
            <HomePage setLayoutData={setLayoutData} setBgColor={setBgColor} />
          }
        />
        <Route
          path="courses-details/:course_id"
          element={<CoursesOverviewPage setBgColor={setBgColor} />}
        />
        <Route
          path="courses-details/:course_id/:section_id/:session_id"
          element={<CourseDetailsPage setBgColor={setBgColor} />}
        />
        <Route
          path="courses-details/:course_id/assignment"
          element={<AssignmentContent setBgColor={setBgColor} />}
        />
        <Route path="*" element={<NotFound />} />
        <Route
          path="course-list"
          element={<CoursesListPage setBgColor={setBgColor} />}
        />
        <Route
          path="ebook/:course_id/:section_id/:session_id/:book_id"
          element={<EbookPage setBgColor={setBgColor} />}
        />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="meeting" element={<ZoomMeeting />} />

        <Route path="user/cart" element={<CartPage />} />

        <Route
          path="profile"
          element={
            <ProfileLayout logOutFun={logOutFun} setBgColor={setBgColor} />
          }
        >
          <Route path="courses" element={<MyCoursesPage />}></Route>
          <Route path="accountInfo" element={<h1>hello from test</h1>}></Route>
          <Route path="wallet" element={<WalletPage />}></Route>
          <Route path="points" element={<h1>hello from test</h1>}></Route>
          <Route path="saveList" element={<SavedListPage />}></Route>
          <Route path="settings" element={<SettingPage />}></Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoute;
